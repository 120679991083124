"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _services = _interopRequireDefault(require("../../services"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var servicesApi = {
  update: function update(id, payload) {
    return (0, _services["default"])().patch("/user/".concat(id), payload).then(function (response) {
      return response.data;
    })["catch"](function (error) {
      return error;
    });
  },
  updateSignature: function updateSignature(id, payload) {
    return (0, _services["default"])().patch("/user/".concat(id), payload).then(function (response) {
      return response.data;
    })["catch"](function (error) {
      return error;
    });
  },
  loadSignature: function loadSignature(id) {
    return (0, _services["default"])().get("/user/".concat(id)).then(function (response) {
      return response.data.signature;
    })["catch"](function (error) {
      return error;
    });
  }
};
var _default = servicesApi;
exports["default"] = _default;