const MODULE = 'USER_GRID';

const actions = {

    LOAD:`LOAD_${MODULE}`,
    LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
    LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
    SEARCH_USER: `SEARCH_USER_${MODULE}`,

    // load
    load: () => {
      return { type: actions.LOAD };
    },
    loadSuccess: data => ({
        type: actions.LOAD_SUCCESS,
        payload: { data },
    }),
    loadError: error => ({
        type: actions.LOAD_ERROR,
        payload: { error },
    }),

    // search
    searchUser: (data, actionName = 'searchuser') => ({
        type: actions.SEARCH_USER,
        payload: { data, actionName }
    }),

};
export default actions;
