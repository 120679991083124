import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import { createServiceREST, ManageError } from 'dcom-app/dist/services';
import actions from './actions';
// import allMails from '../fakeData';

function* filteringMessages(payload) {
  try {
    const servicesApi = createServiceREST('message');

    // console.log(JSON.stringify(payload, null,2));

    // const a = {
    //   type: 'FILTER_ATTRIBUTE',
    //   filterAttr: {
    //     bucket: 'Inbox'
    //   }
    // };

    const filter = {
      // archived: false,
      sort: 'createdAt DESC',
    };
    const result = yield call(servicesApi.list, filter);

    // success
    if (result.isAxiosError) {
      // error
      return ManageError(null, result);
    }

    // success
    yield put(actions.onFilterSuccess(result));
  } catch (error) {
    ManageError(error);
  }
}

export function* filterAction() {
  yield takeEvery(actions.FILTER_ATTRIBUTE, filteringMessages);
}

export default function* rootSaga() {
  yield all([fork(filterAction)]);
}
