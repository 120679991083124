"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _themedefault = _interopRequireDefault(require("./themedefault"));

var _theme = _interopRequireDefault(require("./theme1"));

var _theme2 = _interopRequireDefault(require("./theme2"));

var _theme3 = _interopRequireDefault(require("./theme3"));

var _theme4 = _interopRequireDefault(require("./theme4"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var themes = {
  themedefault: _themedefault["default"],
  theme1: _theme["default"],
  theme2: _theme2["default"],
  theme3: _theme3["default"],
  theme4: _theme4["default"]
};
var _default = themes;
exports["default"] = _default;