"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reducer = _interopRequireDefault(require("./app/reducer"));

var _reducer2 = _interopRequireDefault(require("./auth/reducer"));

var _reducer3 = _interopRequireDefault(require("./customGlobalConfig/reducer"));

var _reducers = _interopRequireDefault(require("../containers/UserProfile/reducers"));

var _reducer4 = _interopRequireDefault(require("./themeSwitcher/reducer"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} // TODO: improve this(dynamically)!!!
// import main reducers from each project.
// import dropsReducers from '../../../drops/src/redux/reducers';
// import partnerPortalReducers from '../../../partner-portal/src/redux/reducers';
// import budgetApplicationReducers from '../../../budget-application/src/redux/reducers';
// import budgetAnalysisToolReducers from '../../../budget-analysis-tool/src/redux/reducers';


function mergeReducers(newReducers) {
  return _objectSpread({
    Auth: _reducer2["default"],
    App: _reducer["default"],
    CustomGlobalConfig: _reducer3["default"],
    ThemeSwitcher: _reducer4["default"],
    UserProfile: _reducers["default"]
  }, newReducers);
}

var _default = mergeReducers;
exports["default"] = _default;