import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  models: [],
  incomExpenses: []
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        incomExpenses: payload.data.budgetData[0].incomeExpenseId,
        errorMessage: false
      };

    case actions.LOAD_INCOME_EXPENSES:
        return {
          ...state,
          isLoading: false,
          incomExpenses: payload.data,
          errorMessage: false
        };

    case actions.LOAD_USER:
      return {
        ...state,
        isLoading: true,
        models: [],
        incomExpenses: [],
        errorMessage: false
    };

    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'User not found'
      };

    default:
      return state;
  }
}
