"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _actions = _interopRequireDefault(require("./auth/actions"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = function _default(store) {
  return new Promise(function () {
    store.dispatch(_actions["default"].checkAuthorization());
  });
};

exports["default"] = _default;