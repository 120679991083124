const MODULE = 'AFFILIATES';

const actions = {
    LIST_AFFILIATES:`LIST_AFFILIATES_${MODULE}`,
    LOAD_AFFILIATE_SUCCESS: `LOAD_AFFILIATE_SUCCESS_${MODULE}`,
    LOAD_AFFILIATES:`LOAD_AFFILIATES_${MODULE}`,
    LOAD_AFFILIATES_ERROR: `LOAD_AFFILIATE_ERROR_${MODULE}`,
    TOGGLE_HANDLE_MODAL: `TOGGLE_HANDLE_MODAL_${MODULE}`,
    SAVE_AFFILIATE: `SAVE_AFFILIATE_${MODULE}`,
    UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
    LOGIN_AS_AFFILIATE: `LOAD_AS_AFFILIATE_${MODULE}`,


    // load
    loadAffiliates: () => {
        return { type: actions.LOAD_AFFILIATES };
    },
    loadAffiliateSuccess: data => ({
        type: actions.LOAD_AFFILIATE_SUCCESS,
        payload: { data },
    }),
    loadAffiliatesError: error => ({
        type: actions.LOAD_AFFILIATES_ERROR,
        payload: { error },
    }),

    // edit
    saveAffiliate: (data, actionName = 'insert') => ({
        type: actions.SAVE_AFFILIATE,
        payload: { data, actionName },
    }),


    // modal
    toggleModal: (data = null) => ({
        type: actions.TOGGLE_HANDLE_MODAL,
        payload: { data },
    }),


    // selected
    updateSelected: data => ({
        type: actions.UPDATE_SELECTED,
        payload: { data}
    }),

    //login as affiliate
    loginAsAffiliate: userId => ({
        type: actions.LOGIN_AS_AFFILIATE,
        payload: { userId }
    })

};
export default actions;
