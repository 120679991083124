/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
const MODULE = 'PERMISSION';

// STATE
export const State = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  models: {
    permissions: [],
    roles: [],
    raw: [],
  },
  selected: null,
};

// ACTIONS
export const actions = {
  CRUD_API: `CRUD_API_${MODULE}`,
  LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
  LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  SELECT_MODEL: `SELECT_MODEL_${MODULE}`,
  LOAD_PERMISSIONS: `LOAD_PERMISSIONS_${MODULE}`,
  LOAD_PERMISSIONS_SUCCESS: `LOAD_PERMISSIONS_SUCCESS_${MODULE}`,

  /**
   * Manage all REST api method.
   * @param actionName {"CREATE" | "DELETE" | "UPDATE" | "READ"}  the method name
   * @param data? {{} | null} The data to send to the server
   * @param callBack? {function} the callback after the operation finished
   * @param endPoint string | Define the end point to make the request depending on lead view or lead error view
   * @return {{payload: {data: *, callBack: *, actionName: *, endPoint: *}, type: string}}
   */
  onCrudApi: (actionName, data = {}, callBack = null, endPoint) => ({
    type: actions.CRUD_API,
    payload: { data, actionName, callBack, endPoint },
  }),

  onLoadSuccess: (data) => ({
    type: actions.LOAD_SUCCESS,
    payload: { data },
  }),

  onLoadError: (error) => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  onSelectModel: (payload) => ({
    type: actions.SELECT_MODEL,
    payload,
  }),

  onLoadPermissions: (callBack) => ({
    type: actions.LOAD_PERMISSIONS,
    payload: { callBack },
  }),

  onLoadPermissionsSuccess: (data) => ({
    type: actions.LOAD_PERMISSIONS_SUCCESS,
    payload: data,
  }),
};

// REDUCER
export function reducer(state = State, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.CRUD_API:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        errorMessage: false,
      };

    case actions.SELECT_MODEL:
      return {
        ...state,
        selected: payload,
      };

    case actions.LOAD_PERMISSIONS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.LOAD_PERMISSIONS_SUCCESS:
      const [permissions, roles] = payload;
      const preparedPermissions = mapPermissions(permissions);
      const preparedRoles = roles.map((x) => {
        return { id: x.id, name: x.name };
      });

      return {
        ...state,
        isLoading: false,
        models: {
          permissions: preparedPermissions,
          roles: preparedRoles,
          raw: permissions,
        },
      };

    default:
      return state;
  }
}

// UTILS
function mapPermissions(permissions = []) {
  const groups = [];
  const groupedPerm = {};

  permissions.forEach((perm) => {
    const name = perm.permissionName;
    if (!groupedPerm[name]) {
      groupedPerm[name] = [];
    }
    groupedPerm[name].push(perm);
  });

  Object.keys(groupedPerm).map((permissionName) => {
    const permissionList = groupedPerm[permissionName];
    groups.push({
      model: permissionName,
      key: `${permissionName}`,
      children: permissionList.map((perm) => {
        return {
          key: perm.id,
          model: perm.permissionType,
          roles: perm.roles,
        };
      }),
    });
  });

  return groups;
}

function mapRoles(permissions = []) {
  const result = [];
  permissions.forEach((permission) => {
    const { roles } = permission;
    roles.forEach((r) => {
      if (!result.find((x) => x.id === r.id)) {
        result.push(r);
      }
    });
  });
  return result;
}
