import service from "dcom-app/dist/services";

const servicesApi = {
    list: () =>
        service()
            .get('/role/2')
            .then(response => response.data)
            .catch(error => error),
    create: payload =>
        service()
            .post('/user', payload)
            .then(response => response.data)
            .catch(error => error),
    update: (id, payload) =>
        service()
            .patch(`/user/${id}`, payload)
            .then(response => response.data)
            .catch(error => error),
    delete: id =>
        service()
            .delete(`/user/${id}`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
