"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledTheme = require("styled-theme");

var _templateObject;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var AntCheckbox = function AntCheckbox(ComponentName) {
  return (0, _styledComponents["default"])(ComponentName)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &.ant-checkbox-wrapper {\n    font-size: 13px;\n    color: ", ";\n    vertical-align: middle;\n\n    .ant-checkbox {\n      top: inherit;\n    }\n  }\n"])), (0, _styledTheme.palette)("text", 1));
};

var _default = AntCheckbox;
exports["default"] = _default;