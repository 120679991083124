import service from "dcom-app/dist/services";

const servicesApi = {
    show: (id) =>
        service()
            .get(`/user/info/${id}/all`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;