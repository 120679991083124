/**
 * Generate left menu by user permission
 * @param profile
 */
export const getSideMenuOptions = permissions => {
  if (!permissions) return [];

  return mapPermissions(permissions);
};

// UTILS
function mapPermissions(permissions = []) {

  let result;

  // 1- SORT BY `ORDER`
  const sortedList = permissions.sort((a, b) => a.order - b.order);

  // 2- FIND THE `ROOT` S
  const roots = sortedList.filter((x)=> x.parent === 0);

  // 3- FIND THE CHILDREN HIERARCHY BY EACH `ROOT`
  result =  getTree(roots, permissions);

  return result;
}

function getTree(roots = [], permissions = []) {
  const result = [];

  roots.forEach( x => {

    const { id, label, parent, route, icon } = x;

    let path = route;
    let isRootChild = false;

    const children = permissions.filter(x => x.parent === id);

    if (children.length > 0 && children[0].action) {
      isRootChild = true;
    }

    if (isRootChild){
      path = children[0].route;
    }

    if (isRootChild) { // rootChild
      const menuItem = {
        "key": path,
        "label": parent ? label : label,
        "leftIcon": icon,
      }
      result.push(menuItem);
    } else {           // root

      const menuItem = {
        "key": label,
        "label": parent ? label : label,
        "leftIcon": icon,
        "children": children.map(y => {

          let path2 = y.route;

          const children = permissions.filter(x => x.parent === y.id);

          if (children.length > 0 ) {
            path2 = children[0].route;
          }

          return {
            "key": path2,
            "label": y.label,
          };

        }),
      }
      result.push(menuItem);
    }
  });
  return result;
}
//
//
// export const sideMenuOptions = [
//   {
//     key: '',
//     label: 'Dashboard',
//     leftIcon: 'ion-ios-speedometer',
//     role: [1, 2]
//   },
//   {
//     key: 'company',
//     label: 'Company',
//     leftIcon: 'ion-ios-home',
//     role: [1]
//   },
//   {
//     key: 'company-info',
//     label: 'Company Info',
//     leftIcon: 'ion-ios-information-outline',
//     role: [1]
//   },
//   {
//     key: 'users-grid',
//     label: 'Users Grid',
//     leftIcon: 'ion-ios-people',
//     role: [1]
//   },
//   {
//     key: 'counselor',
//     label: 'Counselor',
//     leftIcon: 'ion-person-stalker',
//     role: [1]
//   },
//   {
//     key: 'lead',
//     label: 'Leads',
//     leftIcon: 'ion-checkmark',
//     role: [1]
//   },
//   {
//     key: 'app-attributes',
//     label: 'App Attributes',
//     leftIcon: 'ion-ios-pricetags',
//     role: [1]
//   },
//   {
//     key: 'configurations',
//     label: 'Configurations',
//     leftIcon: 'ion-ios-toggle-outline',
//     role: [1],
//     children: [
//       {
//         key: 'categories',
//         label: 'Attribute Categories',
//         role: [1, 2],
//       },
//       {
//         key: 'attribute-group',
//         label: 'Attribute Configuration',
//         role: [1, 2],
//       },
//       {
//         key: 'recommendation-spending',
//         label: 'Spending Configuration',
//         role: [1, 2],
//       }
//     ]
//   },
//   {
//     key: 'documents',
//     label: 'Documents',
//     leftIcon: 'ion-ios-paper',
//     role: [1]
//   },
//   {
//     key: 'messages',
//     label: 'Messages',
//     leftIcon: 'ion-email',
//     role: [1]
//   },
//   // {
//   //   key: 'configuration',
//   //   label: 'System',
//   //   leftIcon: 'ion-gear-b',
//   //   role: [1]
//   // }
// ];
// export default sideMenuOptions;
