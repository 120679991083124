import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import { createApp } from 'dcom-app/dist/containers/main/MainApp';
import bgImage from './assets/images/bk-1.jpg';
import { getAppRouters } from './routers';
import * as serviceWorker from './serviceWorker';
import reducer from './redux/reducers';
import sagas from './sagas';
import { BASE_URL } from './config';
import { getSideMenuOptions } from './sideMenuOptions';

// if (ENV === 'production') {
//   const dsnKey ='https://ebd6f9a152334fbc9b10250c9fcc3152@o412973.ingest.sentry.io/5511815';
//   const releaseName = 'budget-tool-admin-release';
//   init(dsnKey, releaseName);
// }

const config = {
  app: {
    theme: null,
    customStyle: null,
  },
  sideMenuOptions: getSideMenuOptions,
  modules: getAppRouters,
  services: {
    apiUrl: BASE_URL,
  },
  bgImage,
  getReducers: () => reducer,
  getSagas: () => sagas,
};

const MainApp = createApp(config);

ReactDOM.render(MainApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
