"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var actions = {
  SET_APP_CONFIG: 'GET_APP_CONFIG',
  // GET_APP_CONFIG_SUCCESS:'GET_APP_CONFIG_SUCCESS',
  // GET_APP_CONFIG_ERROR:'GET_APP_CONFIG_ERROR',
  setAppConfig: function setAppConfig(payload) {
    return {
      type: actions.SET_APP_CONFIG,
      payload: payload
    };
  } // getAppConfigSuccess: payload => ({
  //   type: actions.GET_APP_CONFIG_SUCCESS,
  //   payload
  // }),
  // getAppConfigError: payload => ({
  //   type: actions.GET_APP_CONFIG_ERROR,
  //   payload
  // })

};
var _default = actions;
exports["default"] = _default;