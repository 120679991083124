import Dashboard from '../containers/dashboard/reducers';
import Company from '../containers/company/reducers';
import CompanyInfo from '../containers/company-info/reducers';
import UsersGrid from '../containers/users-grid/reducers';
import UserDetails from '../containers/user-details/reducers';
import Counselor from '../containers/counselor/reducers';
import Lead from '../containers/lead/reducers';
import AppAttributes from '../containers/app-attributes/reducers';
import ConfigurationGroup from '../containers/config-attributes/reducers';
import ConfigurationSpending from '../containers/config-spending/reducers';
import AppAttributesCategories from '../containers/category/reducers';
import Mails from '../containers/messages/mail/reducer';
import { reducer as Document } from '../containers/documents/controller';
import { reducer as Messages } from '../containers/messages/controller';

import User from '../containers/user/reducers';
import Role from '../containers/role/reducers';
import {reducer as Permissions} from '../containers/permission/controller';
import {reducer as PermissionsUI} from '../containers/permissionUI/controller';

export default {
  Dashboard,
  Company,
  CompanyInfo,
  UsersGrid,
  UserDetails,
  Counselor,
  Lead,
  AppAttributes,
  ConfigurationGroup,
  ConfigurationSpending,
  AppAttributesCategories,
  Document,
  Mails,
  Messages,
  User,
  Role,
  Permissions,
  PermissionsUI
};
