const createFiltersQuery = (filters, rule = 'exact') => {
  let where = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const propName in filters) {
    if (filters[propName]) {
      if (rule === 'exact') {
        where = { ...where, [propName]: `${filters[propName]}` };
      }
      where = { ...where, [propName]: { contains: `${filters[propName]}` } };
    }
  }
  return where;
};

const isEmptyObject = obj => Object.keys(obj).length === 0;

function updateObjectInArray(array, data) {
  return array.map(item => {
    if (item.id !== data.id) {
      return item;
    }
    return {
      ...item,
      ...data,
    };
  });
}

export { createFiltersQuery, isEmptyObject, updateObjectInArray };
