import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from 'antd';
import _  from 'lodash';
import servicesApi from './services';
import actions from './actions';


function* loadCompaniesAttached(data) {
  try {
    const values = data.payload;

    if(values.length===0) {
      yield put(actions.loadCompaniesAttachedSuccess([]));
      return;
    }

    const grouped = _.mapValues(_.groupBy(values, 'companyId'), clist => clist.map(car => _.omit(car, 'companyId')));

    const groupedArray = Object.keys(grouped).map(value => ({id:value, urls: grouped[value].map(u=> u.url)}));

    const result = yield all(groupedArray.map( v => call(servicesApi.companiesAttached, v.id)));

    // success
    if (result === null || !result.isAxiosError) {
      const resultFinal = groupedArray.map(value => {
        const r = result.find( r => `${r.id}` === `${value.id}`);
        return {...value, name: r.name};
      });

      yield put(actions.loadCompaniesAttachedSuccess(resultFinal));
      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* loadDetails(data) {
  try {
    const { id } = data.payload;

    let result = null;

    if (id !== null) {
      result = yield call(servicesApi.loadDetails, id);
    }

    // success
    if (result === null || !result.isAxiosError) {
      yield put(actions.loadDetailsSuccess(result));
      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* load() {
  try {
    const result = yield call(servicesApi.list);

    // success
    if (!result.isAxiosError) {

      yield put(actions.loadSuccess(result));
      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* saveToApi({ payload }) {
  const { data, actionName } = payload;

  const { id, name, properties } = data;

  const updateObj = {
    configData: {
      configName: name
    },
    arrayData: properties
  };

  let result = null;

  try {
    switch (actionName) {
      case 'update':
        result = yield call(servicesApi.updateBulk, id, updateObj);
        setTimeout(() => MessageManager.success('Updated'), 500);
        break;

      case 'delete':
        result = yield call(servicesApi.delete, id);
        setTimeout(() => MessageManager.success('Deleted'), 500);
        break;

      default:
        // insert
        result = yield call(servicesApi.create, { configName: name });
        if (result.id) {
          properties.forEach(i => {
              // eslint-disable-next-line no-param-reassign
            i.configurationGroupId = result.id;
          });

          result = yield call(servicesApi.createBulk, properties);
          setTimeout(() => MessageManager.success('Created'), 500);
        }
        break;
    }

    // success
    if (!result.isAxiosError) {
      yield put({ type: actions.LOAD });
      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD, load),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.LOAD_DETAILS, loadDetails),
    takeEvery(actions.LOAD_COMPANIES_ATTACHED, loadCompaniesAttached)
  ]);
}
