const MODULE = 'DOCUMENTS';

// STATE
export const State = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  models: [],
  total: null,
  selected: null,
};

// ACTIONS
export const actions = {
  CRUD_API: `CRUD_API_${MODULE}`,
  LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
  LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
  LOAD_AND_COUNT_SUCCESS: `LOAD_AND_COUNT_SUCCESS_${MODULE}`,
  LOAD_AND_COUNT_ERROR: `LOAD_AND_COUNT_ERROR_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  SELECT_MODEL: `SELECT_MODEL_${MODULE}`,

  /**
   * Manage all REST api method.
   * @param actionName {"CREATE" | "DELETE" | "UPDATE" | "READ"}  the method name
   * @param data? {{} | null} The data to send to the server
   * @param callBack? {function} the callback after the operation finished
   * @return {{payload: {data: *, callBack: *, actionName: *}, type: string}}
   */
  onCrudApi: (actionName, data = {}, callBack = null) => ({
    type: actions.CRUD_API,
    payload: { data, actionName, callBack },
  }),

  onLoadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: { data },
  }),

  onLoadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  onLoadAndCountSuccess: (data, count) => ({
    type: actions.LOAD_AND_COUNT_SUCCESS,
    payload: { data, count },
  }),

  onLoadAndCountError: error => ({
    type: actions.LOAD_AND_COUNT_ERROR,
    payload: { error },
  }),

  onSelectModel: payload => ({
    type: actions.SELECT_MODEL,
    payload,
  }),
};

// REDUCER
export function reducer(state = State, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.CRUD_API:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        errorMessage: false,
      };

    case actions.LOAD_AND_COUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        total: payload.count,
        errorMessage: false,
      };
    }
    case actions.SELECT_MODEL:
      return {
        ...state,
        selected: payload,
      };

    default:
      return state;
  }
}
