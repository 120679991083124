const MODULE = 'USER_DETAILS';

const actions = {

    LOAD_USER: `LOAD_USER_${MODULE}`,
    LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
    LOAD_INCOME_EXPENSES: `LOAD_INCOME_EXPENSES_${MODULE}`,
    LOAD_ERROR: `LOAD_ERROR_${MODULE}`,

    loadUser: data => ({
        type: actions.LOAD_USER,
        payload: { data }
    }),
    loadSuccess: data => ({
        type: actions.LOAD_SUCCESS,
        payload: { data },
    }),
    loadIncomeExpenses: data => ({
        type: actions.LOAD_INCOME_EXPENSES,
        payload: { data },
    }),
    loadError: error => ({
        type: actions.LOAD_ERROR,
        payload: { error },
    }),


};
export default actions;
