const MODULE = 'CONFIG-GROUP';

const actions = {

    LOAD: `LOAD_${MODULE}`,
    LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
    LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
    SAVE_TO_API: `SAVE_TO_API_${MODULE}`,
    TOGGLE_HANDLE_MODAL: `TOGGLE_HANDLE_MODAL_${MODULE}`,
    UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
    LOAD_DETAILS: `LOAD_DETAILS_${MODULE}`,
    LOAD_DETAILS_SUCCESS: `LOAD_DETAILS_SUCCESS_${MODULE}`,
    LOAD_COMPANIES_ATTACHED: `LOAD_COMPANIES_ATTACHED_${MODULE}`,
    LOAD_COMPANIES_ATTACHED_SUCCESS: `LOAD_COMPANIES_ATTACHED_SUCCESS_${MODULE}`,

    // load
    load: () => {
        return {type: actions.LOAD};
    },
    loadSuccess: data => ({
        type: actions.LOAD_SUCCESS,
        payload: {data},
    }),
    loadError: error => ({
        type: actions.LOAD_ERROR,
        payload: {error},
    }),

    // edit
    saveToApi: (data, actionName = 'insert') => ({
        type: actions.SAVE_TO_API,
        payload: {data, actionName},
    }),

    // modal
    toggleModal: (data = null) => ({
        type: actions.TOGGLE_HANDLE_MODAL,
        payload: {data},
    }),

    // selected
    updateSelected: data => ({
        type: actions.UPDATE_SELECTED,
        payload: {data}
    }),
    loadDetails: id => ({
        type: actions.LOAD_DETAILS,
        payload: {id}
    }),
    loadDetailsSuccess: (data) => ({
        type: actions.LOAD_DETAILS_SUCCESS,
        payload: {data}
    }),

    loadCompaniesAttached: data => ({
        type: actions.LOAD_COMPANIES_ATTACHED,
        payload: data
    }),
    loadCompaniesAttachedSuccess: (data) => ({
        type: actions.LOAD_COMPANIES_ATTACHED_SUCCESS,
        payload: {data}
    }),

};
export default actions;
