import dashBoardSagas from '../containers/dashboard/sagas';
import companySagas from '../containers/company/sagas';
import companyInfoSagas from '../containers/company-info/sagas';
import counselorSagas from '../containers/counselor/sagas';
import leadSagas from '../containers/lead/sagas';
import usersSagas from '../containers/users-grid/sagas';
import userDetailSagas from '../containers/user-details/sagas';
import appAttributesSagas from '../containers/app-attributes/sagas';
import configurationGroupSagas from '../containers/config-attributes/sagas';
import configurationSpending from '../containers/config-spending/sagas';
import appAttributeCategory from '../containers/category/sagas';
import documentSagas from '../containers/documents/services';
import mailSagas from '../containers/messages/mail/saga';
import messagesSagas from '../containers/messages/services';

import userSagas from '../containers/user/sagas';
import roleSagas from '../containers/role/sagas';
import permissionSagas from '../containers/permission/services';
import permissionSagasUI from '../containers/permissionUI/services';

export default [
  dashBoardSagas(),
  companySagas(),
  companyInfoSagas(),
  counselorSagas(),
  leadSagas(),
  appAttributesSagas(),
  configurationGroupSagas(),
  configurationSpending(),
  appAttributeCategory(),
  usersSagas(),
  userDetailSagas(),
  documentSagas(),
  mailSagas(),
  messagesSagas(),
  userSagas(),
  roleSagas(),
  permissionSagas(),
  permissionSagasUI()
];
