import service from "dcom-app/dist/services";

const servicesApi = {
    list: () =>
        service()
            .get('/role')
            .then(response => response.data)
            .catch(error => error),
    create: payload =>
        service()
            .post('/role', payload)
            .then(response => response.data)
            .catch(error => error),
    update: (id, payload) =>
        service()
            .patch(`/role/${id}`, payload)
            .then(response => response.data)
            .catch(error => error),
    delete: id =>
        service()
            .delete(`/role/${id}`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
