import actions from './actions';
import { updateObjectInArray } from '../../helpers/index';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  lmodels: [],
  selected: null,
  total: 0,
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.LOAD:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lmodels: payload.data,
        errorMessage: false,
      };

    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.LOAD_AND_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lmodels: payload.data,
        total: payload.total,
        errorMessage: '',
      };
    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        selected: payload.data || null,
      };

    case actions.UPDATE_SELECTED:
      return {
        ...state,
        selected: payload.data,
      };
    case actions.CLEAN:
      return {
        ...state,
        lmodels: payload,
      };
    case actions.SAVE_TO_API_SUCCESS: {
      const { data, actionName } = payload;
      if (actionName === 'delete') {
        return {
          ...state,
          lmodels: state.lmodels.filter(model => model.id !== data.id),
        };
      }
      if (actionName === 'create') {
        return {
          ...state,
          lmodels: [...state.lmodels, data],
        };
      }
      if (actionName === 'update') {
        return {
          ...state,
          lmodels: updateObjectInArray(state.lmodels, data),
        };
      }
      return state;
    }
    default:
      return state;
  }
}
