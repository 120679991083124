import { all, takeEvery, call, put } from 'redux-saga/effects';
import servicesApi from './services';
import actions from './actions';

function monthName(dt) {
  const mlist = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return mlist[dt.getMonth()];
}

function parseUserData(apiUserInfo) {
  const defaultUserInfo = {
    city: '',
    zipcode: 'xxxxx',
    financialGoals: ['Pay Off Credit Card Debt', 'Pay Off Student Loans'],
    userStatuses: [
      'Home-owner', // could be one of these: Rented, Family member, Home-owner (paying mortgage), Home-owner (mortgage paid)
      'Married', // could be one of these: Married, Single, Engaged
      'Parent', // could be added or not
    ],
  };

  const auxUserInfo = {
    budgetData: apiUserInfo.budgetData,
    globalUserData: { ...apiUserInfo.globalUserData, ...defaultUserInfo },
  };

  for (let i = 0; i < auxUserInfo.budgetData.length; i += 1) {
    const currentDate = new Date(auxUserInfo.budgetData[i].date);
    auxUserInfo.budgetData[i].parseDate = `${monthName(currentDate)} ${currentDate.getFullYear()}`;
  }

  return auxUserInfo;
}

function* loadUser({ payload }) {
  const { data } = payload;

  let details = null;
  let result = null;
  try {
    result = yield call(servicesApi.show, data);
    details = parseUserData(result);
    // debugger

    /* details = {
            "userData":[{
                "userId":{
                    "id": 1,
                    "username": "tiff",
                    "email": "tiffany@debt.com",
                    "firstName": "Tiffany",
                    "lastName": "Russell",
                    "city": "Coral Springs",
                    "state": "Florida",
                    "zipcode": "33315",
                    "hau": "",
                    "companyInfoId": 2,
                    "financialGoals": [
                        "Pay Off Credit Card Debt",
                        "Pay Off Student Loans"
                    ],
                    "userStatuses":[
                        "Home-owner", // could be one of these: Rented, Family member, Home-owner (paying mortgage), Home-owner (mortgage paid)
                        "Married", // could be one of these: Married, Single, Engaged
                        "Parent" // could be added or not
                    ]
                },

                "appTransportationId":[],
                "appLoansId":[],
                "appCreditorId":[],
                "id": 1,
                "date": "",

                "incomeExpenseId":[
                    {
                        "budgetDateId" : "january_2020",
                        "budgetDate" : "January 2020",
                        "incomeExpenses": [
                            {
                                "type": "income",
                                'list': [
                                    {
                                        "id": 1,
                                        "title": "Paycheck A 1",
                                        "monthlyAmount": 4210,
                                        "description": "decription Paycheck A 1",
                                        "appAttr":[]
                                    },
                                    {
                                        "id": 2,
                                        "title": "Paycheck A 2",
                                        "monthlyAmount": 1030,
                                        "description": "decription Paycheck A 2",
                                        "appAttr":[]
                                    },
                                    {
                                        "id": 3,
                                        "title": "Other source of income A",
                                        "monthlyAmount": 150.50,
                                        "description": "decription Paycheck A 3",
                                        "appAttr":[]
                                    },
                                ]
                            },
                            {
                                "type": "expenses",
                                'list': [
                                    {
                                        "id": "housing",
                                        "title": "Housing A",
                                        "monthlyAmount": 0,
                                        "description": "decription Paycheck A 1",
                                        "appAttr":[
                                            {
                                                "id": 1,
                                                "title": "Mortage",
                                                "monthlyAmount": 1500,
                                                "description": "decription about housing",
                                            },
                                            {
                                                "id": 2,
                                                "title": "Electricity",
                                                "monthlyAmount": 89.50,
                                                "description": "Electricity payments",
                                            },
                                            {
                                                "id": 3,
                                                "title": "TV & Internet",
                                                "monthlyAmount": 110,
                                                "description": "AT&T monthly bills",
                                            },
                                        ]
                                    },
                                    {
                                        "id": "transportation",
                                        "title": "Transportation A",
                                        "appAttr":[
                                            {
                                                "id": 1,
                                                "title": "Bus Tickets",
                                                "monthlyAmount": 75,
                                                "description": "Tickets for the bus",
                                            },
                                            {
                                                "id": 2,
                                                "title": "Gas",
                                                "monthlyAmount": 100,
                                                "description": "6 gallons of gas refield every week",
                                            },
                                            {
                                                "id": 3,
                                                "title": "Uber",
                                                "monthlyAmount": 57,
                                                "description": "Personal commuting",
                                            }
                                        ]
                                    }

                                ]// - expensesList array
                            }
                        ]// - incomeExpenses array of January
                    },// . January obj

                    {
                        "budgetDateId" : "february_2020",
                        "budgetDate" : "February 2020",
                        "incomeExpenses": [
                            {
                                "type": "income",
                                'list': [
                                    {
                                        "id": 1,
                                        "title": "Paycheck B 1",
                                        "monthlyAmount": 4210,
                                        "description": "decription Paycheck B 1",
                                        "appAttr":[]
                                    },
                                    {
                                        "id": 2,
                                        "title": "Paycheck B 2",
                                        "monthlyAmount": 1030,
                                        "description": "decription Paycheck B 2",
                                        "appAttr":[]
                                    },
                                    {
                                        "id": 3,
                                        "title": "Other source of income B",
                                        "monthlyAmount": 150.50,
                                        "description": "decription Paycheck B 3",
                                        "appAttr":[]
                                    },
                                ]
                            },
                            {
                                "type": "expenses",
                                'list': [
                                    {
                                        "id": "housing",
                                        "title": "Housing B",
                                        "monthlyAmount": 0,
                                        "description": "decription Paycheck B 1",
                                        "appAttr":[
                                            {
                                                "id": 1,
                                                "title": "Mortage",
                                                "monthlyAmount": 1500,
                                                "description": "decription about housing",
                                            },
                                            {
                                                "id": 2,
                                                "title": "Electricity",
                                                "monthlyAmount": 89.50,
                                                "description": "Electricity payments",
                                            },
                                            {
                                                "id": 3,
                                                "title": "TV & Internet",
                                                "monthlyAmount": 110,
                                                "description": "AT&T monthly bills",
                                            },
                                        ]
                                    },
                                    {
                                        "id": "transportation",
                                        "title": "Transportation B",
                                        "appAttr":[
                                            {
                                                "id": 1,
                                                "title": "Bus Tickets",
                                                "monthlyAmount": 75,
                                                "description": "Tickets for the bus",
                                            },
                                            {
                                                "id": 2,
                                                "title": "Gas",
                                                "monthlyAmount": 100,
                                                "description": "6 gallons of gas refield every week",
                                            },
                                            {
                                                "id": 3,
                                                "title": "Uber",
                                                "monthlyAmount": 57,
                                                "description": "Personal commuting",
                                            }
                                        ]
                                    }

                                ]// - expensesList array
                            }
                        ]// - incomeExpenses array of February
                    },// . February obj

                ],// incomeExpenseId



            }]
        }; */

    yield put(actions.loadSuccess(details));
    return;

    // success
    /*         if (!result.isAxiosError) {
            yield put(actions.loadSuccess(details));
            return;
        }

        // error
        const {message} = result;
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        } */
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_USER, loadUser)]);
}
