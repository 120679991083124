"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CheckboxGroup = void 0;

var _antd = require("antd");

var _checkbox = _interopRequireDefault(require("./checkbox.style"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var checkbox = (0, _checkbox["default"])(_antd.Checkbox);
var CheckboxGroup = _antd.Checkbox.Group;
exports.CheckboxGroup = CheckboxGroup;
var _default = checkbox;
exports["default"] = _default;