const MODULE = 'LEAD';

const actions = {
  LOAD: `LOAD_${MODULE}`,
  LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
  LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
  LOAD_AND_COUNT: `LOAD_AND_COUNT_${MODULE}`,
  LOAD_AND_COUNT_SUCCESS: `LOAD_AND_COUNT_SUCCESS_${MODULE}`,
  LOAD_AND_COUNT_ERROR: `LOAD_AND_COUNT_ERROR_${MODULE}`,
  SAVE_TO_API: `SAVE_TO_API_${MODULE}`,
  SAVE_TO_API_SUCCESS: `SAVE_TO_API_SUCCESS_${MODULE}`,
  TOGGLE_HANDLE_MODAL: `TOGGLE_HANDLE_MODAL_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  CLEAN: `CLEAN_${MODULE}`,

  // load
  load: payload => {
    return { type: actions.LOAD, payload };
  },
  loadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: { data },
  }),
  // load and count
  loadAndCountSuccess: (data, total) => ({
    type: actions.LOAD_AND_COUNT_SUCCESS,
    payload: { data, total },
  }),
  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  // edit
  saveToApi: (data, actionName) => ({
    type: actions.SAVE_TO_API,
    payload: { data, actionName },
  }),
  saveToApiSuccess: (data, actionName) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: { data, actionName },
  }),
  // modal
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_HANDLE_MODAL,
    payload: { data },
  }),

  // selected
  updateSelected: data => ({
    type: actions.UPDATE_SELECTED,
    payload: { data },
  }),

  /**
   * Filter leads by parameters
   * @param firstName { string}
   * @param email {string}
   * @returns {{payload: {firstName: *, email: *}, type: string}}
   */
  filter: ({ firstName, email }) => ({
    type: actions.FILTER,
    payload: { firstName, email },
  }),

  /**
   * Clean lead loaded
   * @param data
   * @returns {{payload: {firstName: *, email: *}, type: string}}
   */
  clean: data => ({
    type: actions.CLEAN,
    payload: data ?? [],
  }),
};
export default actions;
