import actions from "./actions";

const initState = {
    isLoading: false,
    errorMessage: false,
    selected: null,
    dashBoard: {},
};

export default function reducer(state = initState, action) {
    const {type, payload} = action;
    switch (type) {

        case actions.LOAD:
            return {
                ...state,
                isLoading: true,
            };

        case actions.LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dashBoard: payload.data,
            };

        case actions.LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload.error,
            };

        default:
            return state;
    }
}

