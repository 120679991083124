import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from 'antd';
import servicesApi from './services';
import actions from './actions';

// function* filterLeads({ payload }) {
//   try {
//     const { firstName, email } = payload;

//     const result = yield call(servicesApi.filter, firstName ?? '', email ?? '');

//     // success
//     if (!result.isAxiosError) {
//       yield put(actions.loadSuccess(result));
//       return;
//     }

//     // error
//     const { message } = result;
//     yield put(actions.loadError({ error: message }));

//     if (result.response && result.response.status === 401) {
//       MessageManager.error('Session expired please login again');
//     }
//   } catch (error) {
//     yield put(actions.loadError(error));
//   }
// }

function* loadLeads({ payload }) {
  const { actionName = 'LOAD_AND_COUNT', data = {} } = payload;
  let result = [];
  let total = 0;
  try {
    if (actionName === 'LOAD') {
      result = yield call(servicesApi.list, data);
      if (!result.isAxiosError) {
        yield put(actions.loadSuccess(result));
        return;
      }
    }

    if (actionName === 'LOAD_AND_COUNT') {
      [total, result] = yield all([call(servicesApi.count), call(servicesApi.list, data)]);
      if (!total.isAxiosError || !result.isAxiosError) {
        yield put(actions.loadAndCountSuccess(result, total.count));
        return;
      }
    }
    // TODO PAGINATION FOR FILTERED RESULTS
    if (actionName === 'LOAD_AND_FILTER') {
      result = yield call(servicesApi.list, data);
      if (!total.isAxiosError) {
        const total = result.length || 0;
        yield put(actions.loadAndCountSuccess(result, total));
        return;
      }
    }
    // error
    const { message } = result;
    yield put(actions.loadError({ error: message }));

    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again', 5);
    }
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* saveToApi({ payload }) {
  const { data, actionName = 'create' } = payload;

  const { id = null } = data;

  let result = null;
  try {
    if (actionName !== 'create') result = yield call(servicesApi[actionName], id, data);
    if (actionName === 'create') result = yield call(servicesApi[actionName], data);

    // success
    if (!result.isAxiosError) {
      yield put(actions.saveToApiSuccess(result, actionName));
      MessageManager.success(`Lead ${actionName} Success`, 5);
      return;
    }

    // error
    const { message } = result;
    yield put(actions.loadError({ error: message }));
    MessageManager.error(`Lead ${actionName} ${message}`, 5);
    console.log(('Lead', message));
    if (result.response && result.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.loadError(error));
    MessageManager.error(`Lead ${actionName} Error ${error}`, 5);
    console.log(('Lead', error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, loadLeads), takeEvery(actions.SAVE_TO_API, saveToApi)]);
}
