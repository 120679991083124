import {all, takeEvery, call, put} from 'redux-saga/effects';
import { message as MessageManager } from "antd";
import servicesApi from "./services";
import actions from './actions';

function* loadUsers() {
  try {

      const result = yield call(servicesApi.list);

      // success
      if (!result.isAxiosError) {
          yield put(actions.loadSuccess(result));
          return;
      }

      // error
      const {message} = result;
      yield put(actions.loadError({error: message}));

      if (result.response && result.response.status === 401) {
          MessageManager.error('Session expired please login again');
      }

  } catch (error) {
      yield put(actions.loadError(error));
  }
}

function* searchUser({payload}) {
    const {data, actionName} = payload;

    let result = null;
    let users = [];
    try {
        if(actionName === 'searchuser'){
            result = yield call(servicesApi.search, data);
        }

        // success
        if (!result.isAxiosError && result.response.total > 0) {

            users = result.response.hits.map( hit => {
                return { 'id': hit._id, ...hit._source };
            });

            yield put(actions.loadSuccess(users));
            return;
        }

        // error
        const {message} = result;
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        yield put(actions.loadError(error));
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD, loadUsers),
        takeEvery(actions.SEARCH_USER, searchUser),
    ]);
}

