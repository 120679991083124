/* eslint-disable no-case-declarations,camelcase,no-nested-ternary */
const MODULE = 'MESSAGES';

// STATE
export const State = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  models: [],
  selected: null,
};

// ACTIONS
export const actions = {
  CRUD_API: `CRUD_API_${MODULE}`,
  LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
  LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  SELECT_MODEL: `SELECT_MODEL_${MODULE}`,
  CREATE_MESSAGE: `CREATE_MESSAGE${MODULE}`,

  /**
   * Manage all REST api method.
   * @param actionName {"CREATE" | "DELETE" | "UPDATE" | "READ"}  the method name
   * @param data? {{} | null} The data to send to the server
   * @param callBack? {function} the callback after the operation finished
   * @return {{payload: {data: *, callBack: *, actionName: *}, type: string}}
   */
  onCrudApi: (actionName, data = {}, callBack = null) => ({
    type: actions.CRUD_API,
    payload: { data, actionName, callBack },
  }),

  onLoadSuccess: (data) => ({
    type: actions.LOAD_SUCCESS,
    payload: { data },
  }),

  onLoadError: (error) => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  onSelectModel: (payload) => ({
    type: actions.SELECT_MODEL,
    payload,
  }),

  onCreateMessage: (data, callBack) => ({
    type: actions.CREATE_MESSAGE,
    payload: { data, callBack },
  }),
};

// REDUCER
export function reducer(state = State, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.CRUD_API:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_SUCCESS:
      // bucket: "Inbox"

      let values = JSON.parse(JSON.stringify(payload.data));

      values = values.map((x) => {
        const { creator_id, bucket } = x;

        return {
          ...x,
          bucket:
            bucket !== undefined && bucket === 'offer'
              ? 'Offer'
              : creator_id !== undefined && creator_id?.id === 1
              ? 'Sent'
              : 'Inbox',
        };
      });

      return {
        ...state,
        isLoading: false,
        models: values,
        errorMessage: false,
      };

    case actions.SELECT_MODEL:
      return {
        ...state,
        selected: payload,
      };

    default:
      return state;
  }
}
