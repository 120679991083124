import { message as MessageManager } from 'antd';
import service from 'dcom-app/dist/services';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { actions } from './controller';

const ManageError = (error, response) => {
  if (error) {
    MessageManager.error(`${error}`);
    return;
  }

  const { message } = response;

  if (response && response.status === 401) {
    MessageManager.error('Session expired please login again');
    return;
  }

  MessageManager.error(`${message}`);
};

function createServiceREST(endpoint) {
  return {
    list: (payload) =>
      service()
        .get(`/${endpoint}`, { params: payload })
        .then((response) => response.data)
        .catch((error) => error),
    create: (payload) =>
      service()
        .post(`/${endpoint}`, payload)
        .then((response) => response.data)
        .catch((error) => error),
    update: (id, payload) =>
      service()
        .patch(`/${endpoint}/${id}`, payload)
        .then((response) => response.data)
        .catch((error) => error),
    delete: (id) =>
      service()
        .delete(`/${endpoint}/${id}`)
        .then((response) => response.data)
        .catch((error) => error),
  };
}

function* crudToApi({ payload }) {
  const { data, actionName, callBack } = payload;

  const servicesApi = createServiceREST('permissionui');
  const servicesRoles = createServiceREST('role');

  const id = data ? data.id : 0;
  let result = null;

  try {
    switch (actionName) {
      case 'CREATE': {
        const { permissions } = data;
        const parent = permissions[0];

        if (parent.label) {
          result = yield call(servicesApi.create, parent);
        }

        const pID = result ? result.id : parent.parent;

        if (permissions.length > 1) {
          const [, ...rest] = permissions;
          const calls = rest.map((x) => call(servicesApi.create, { ...x, parent: pID }));
          result = yield all(calls);
        }
        break;
      }

      case 'READ': {
        const permissions = call(servicesApi.list, { ...data, limit: 1000 });
        const roles = call(servicesRoles.list);
        result = yield all([permissions, roles]);

        break;
      }

      case 'UPDATE':
        delete data.id;
        result = yield call(servicesApi.update, id, data);
        break;

      case 'DELETE': {
        const { permIds } = data;
        const calls = permIds.map((permId) => call(servicesApi.delete, permId));
        result = yield all(calls);
        break;
      }
      default:
        break;
    }

    // success
    if (result.isAxiosError) {
      // error
      return ManageError(null, result);
    }

    // success
    if (actionName === 'READ') {
      yield put(actions.onLoadPermissionsSuccess(result));
    } else {
      // yield put({ type: actions.CRUD_API, payload: { actionName: 'READ' } });
    }

    if (callBack) {
      callBack();
    }
    return null;
  } catch (error) {
    return ManageError(error);
  }
}

function* loadPermissions({ payload }) {
  try {
    const { callBack } = payload;

    const serviPermission = createServiceREST('permission-ui');

    const result = yield call(serviPermission.list, {});

    if (result.isAxiosError) {
      // if ERROR
      return ManageError(null, result);
    }

    // success
    yield put(actions.onLoadPermissionsSuccess(result));

    if (callBack) {
      callBack();
    }
    return null;
  } catch (error) {
    return ManageError(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CRUD_API, crudToApi), takeEvery(actions.LOAD_PERMISSIONS, loadPermissions)]);
}
