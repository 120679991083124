"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledTheme = require("styled-theme");

var _templateObject;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var AppHolder = _styledComponents["default"].div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .trigger {\n    font-size: 18px;\n    line-height: 64px;\n    padding: 0 16px;\n    cursor: pointer;\n    transition: color 0.3s;\n  }\n\n  .trigger:hover {\n    color: ", ";\n  }\n\n  .ant-layout-sider-collapsed .anticon {\n    font-size: 16px;\n  }\n\n  .ant-layout-sider-collapsed .nav-text {\n    display: none;\n  }\n\n  .ant-layout {\n    background: ", ";\n\n    &.isoContentMainLayout {\n      overflow: auto;\n      overflow-x: hidden;\n      @media only screen and (min-width: 768px) and (max-width: 1220px) {\n        width: calc(100% - 80px);\n        flex-shrink: 0;\n      }\n\n      @media only screen and (max-width: 767px) {\n        width: 100%;\n        flex-shrink: 0;\n      }\n    }\n  }\n\n  .isoLayoutContent {\n    width: 100%;\n    padding: 35px;\n    background-color: #ffffff;\n    border: 1px solid ", ";\n    height: 100%;\n  }\n\n  .isomorphicLayout {\n    width: calc(100% - 240px);\n    flex-shrink: 0;\n    overflow-x: hidden !important;\n\n    @media only screen and (max-width: 767px) {\n      width: 100%;\n    }\n\n    @media only screen and (min-width: 768px) and (max-width: 1220px) {\n      width: calc(100% - 80px);\n    }\n  }\n\n  .ant-layout-footer {\n    font-size: 13px;\n    @media (max-width: 767px) {\n      padding: 10px 20px;\n    }\n  }\n\n  button {\n    border-radius: 0;\n  }\n"])), (0, _styledTheme.palette)("primary", 0), (0, _styledTheme.palette)("secondary", 1), (0, _styledTheme.palette)("border", 0));

var _default = AppHolder;
exports["default"] = _default;