import {all, takeEvery, call, put} from 'redux-saga/effects';

import actions from './actions';
import { servicesConfigurationSpending, servicesConfigurationSpendingCategory } from './services';
import { message as MessageManager } from 'antd';

/**
 *
 * @returns {Generator<*, void, *>}
 */
function* load() {
    try {

        const result = yield call(servicesConfigurationSpending.list);

        // success
        if (!result.isAxiosError) {
            yield put(actions.loadSuccess(result));
            return;
        }

        // error
        const {message} = result;
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        yield put(actions.loadError(error));
    }
}

/**
 *
 * @param configurationId
 * @param state
 * @returns {Generator<*, void, *>}
 */
function* loadAttributes({configurationId, ...state}) {
    try {

        const params = {
            where: JSON.stringify({
                configurationSpendingId: configurationId
            })
        }

        const result = yield call(servicesConfigurationSpendingCategory.list, params);

        // success
        if (!result.isAxiosError) {
            yield put(actions.loadAttributesSuccess(result));
            return;
        }

        // error
        const {message} = result;
        yield put(actions.loadAttributesError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log('error', error);
        yield put(actions.loadAttributesError(error));
    }
}

/**
 *
 * @param payload
 * @returns {Generator<*, void, null>}
 */
function* saveToApi({payload}) {
    const {data: {id, name, attributeConfigId, attributes}, actionName} = payload;

    let result = null;
    try {
        switch (actionName) {
            case 'update':
                result = yield call(servicesConfigurationSpending.update, id, {name, attributeConfigId});
                setTimeout(()=> MessageManager.success('Updated'),500);
                break;

            case 'delete':
                result = yield call(servicesConfigurationSpending.delete, id);
                setTimeout(()=> MessageManager.success('Deleted'),500);
                break;

            default:
                // insert
                result = yield call(servicesConfigurationSpending.create, {name, attributeConfigId});

                if (result && result.id) {
                    yield all(attributes.map((attribute) => {
                        return call(servicesConfigurationSpendingCategory.create, {
                            lowPercentage: attribute.lowPercentage || 0,
                            highPercentage: attribute.highPercentage || 0,
                            categoryId: attribute.category || null,
                            configurationSpendingId: result.id
                        });
                    }));
                }

                setTimeout(()=> MessageManager.success('Created'),500);
                break;
        }

        // success
        if(!result.isAxiosError){
            yield put({type: actions.LOAD});
            return;
        }

        // error
        const {message} = result;
        yield put(actions.loadError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        yield put(actions.loadError(error));
    }
}

function* saveAttributesToApi({payload}) {
    const {data, actionName} = payload;
    const {id, categoryId, lowPercentage, highPercentage, configurationSpendingId } = data;

    let result = null;
    try {

        switch (actionName) {
            case 'update':
                result = yield call(servicesConfigurationSpendingCategory.update, id, data);
                setTimeout(()=> MessageManager.success('Updated'),500);
                break;

            case 'delete':
                result = yield call(servicesConfigurationSpendingCategory.delete, id);
                setTimeout(()=> MessageManager.success('Deleted'),500);
                break;

            default:
                // insert
                result = yield call(servicesConfigurationSpendingCategory.create, {
                    lowPercentage,
                    highPercentage,
                    categoryId,
                    configurationSpendingId
                });
                setTimeout(()=> MessageManager.success('Created'),500);
                break;
        }

        // success
        if(!result.isAxiosError && configurationSpendingId){
            yield put({type: actions.LOAD_ATTRIBUTES, configurationId: configurationSpendingId});
            return;
        }

        // error
        const {message} = result;
        yield put(actions.loadAttributesError({error: message}));

        if (result.response && result.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        yield put(actions.loadAttributesError(error));
    }
}

/**
 *
 * @returns {Generator<*, void, *>}
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD, load),
        takeEvery(actions.LOAD_ATTRIBUTES, loadAttributes),
        takeEvery(actions.SAVE_TO_API, saveToApi),
        takeEvery(actions.SAVE_ATTRIBUTES_TO_API, saveAttributesToApi),
    ]);
}

