"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.getView = getView;

function getView(width) {
  var newView = 'MobileView';

  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }

  return newView;
}

var actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLEAR_MENU: 'CLEAR_MENU',
  UPDATE_MESSAGE_COUNT: 'UPDATE_MEESAGE_COUNT',
  SET_SIDE_MENU: 'SET_SIDE_MENU',
  toggleCollapsed: function toggleCollapsed() {
    return {
      type: actions.COLLPSE_CHANGE
    };
  },
  toggleAll: function toggleAll(width, height) {
    var view = getView(width);
    var collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed: collapsed,
      view: view,
      height: height
    };
  },
  toggleOpenDrawer: function toggleOpenDrawer() {
    return {
      type: actions.COLLPSE_OPEN_DRAWER
    };
  },
  changeOpenKeys: function changeOpenKeys(openKeys) {
    return {
      type: actions.CHANGE_OPEN_KEYS,
      openKeys: openKeys
    };
  },
  changeCurrent: function changeCurrent(current) {
    return {
      type: actions.CHANGE_CURRENT,
      current: current
    };
  },
  clearMenu: function clearMenu() {
    return {
      type: actions.CLEAR_MENU
    };
  },
  updateMessagesCount: function updateMessagesCount(value) {
    return {
      type: actions.UPDATE_MESSAGE_COUNT,
      value: value
    };
  },
  setSideMenu: function setSideMenu(menu) {
    return {
      type: actions.SET_SIDE_MENU,
      menu: menu
    };
  }
};
var _default = actions;
exports["default"] = _default;