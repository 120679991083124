"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _antd = require("antd");

var Notification = function Notification(type, message, description) {
  _antd.notification[type]({
    message: message,
    description: description
  });
};

var _default = Notification;
exports["default"] = _default;