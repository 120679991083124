const MODULE = 'PERMISSION-UI';

// STATE
export const State = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  models: {
    permissions: [],
    roles: [],
    raw: []
  },
  selected: null,
};


// ACTIONS
export const actions = {
  CRUD_API: `CRUD_API_${MODULE}`,
  LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
  LOAD_ERROR: `LOAD_ERROR_${MODULE}`,
  UPDATE_SELECTED: `UPDATE_SELECTED_${MODULE}`,
  SELECT_MODEL: `SELECT_MODEL_${MODULE}`,
  LOAD_PERMISSIONS: `LOAD_PERMISSIONS_${MODULE}`,
  LOAD_PERMISSIONS_SUCCESS: `LOAD_PERMISSIONS_SUCCESS_${MODULE}`,

  /**
   * Manage all REST api method.
   * @param actionName {"CREATE" | "DELETE" | "UPDATE" | "READ"}  the method name
   * @param data? {{} | null} The data to send to the server
   * @param callBack? {function} the callback after the operation finished
   * @param endPoint string | Define the end point to make the request depending on lead view or lead error view
   * @return {{payload: {data: *, callBack: *, actionName: *, endPoint: *}, type: string}}
   */
  onCrudApi: (actionName, data = {}, callBack = null, endPoint) => ({
    type: actions.CRUD_API,
    payload: { data, actionName, callBack, endPoint },
  }),

  onLoadSuccess: data => ({
    type: actions.LOAD_SUCCESS,
    payload: { data },
  }),

  onLoadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  onSelectModel: payload => ({
    type: actions.SELECT_MODEL,
    payload,
  }),

  onLoadPermissions: (callBack) => ({
    type: actions.LOAD_PERMISSIONS,
    payload: {callBack},
  }),

  onLoadPermissionsSuccess: (data) => ({
    type: actions.LOAD_PERMISSIONS_SUCCESS,
    payload: data,
  }),


};

// REDUCER
export function reducer(state = State, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.CRUD_API:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        errorMessage: false,
      };

    case actions.SELECT_MODEL:
      return {
        ...state,
        selected: payload,
      };

    case actions.LOAD_PERMISSIONS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_PERMISSIONS_SUCCESS:

      const [permissions, roles] = payload;

      const preparedPermissions = mapPermissions(permissions);
      const preparedRoles = roles.map((x)=> {return{id: x.id, name: x.name}});

      return {
        ...state,
        isLoading: false,
        models: {
          permissions: preparedPermissions,
          roles: preparedRoles,
          raw: permissions
        }
      };

    default:
      return state;
  }
}

function getTree(roots = [], permissions = []) {
  const result = [];

  roots.forEach(x => {

    const { id, label, parent, roles } = x;
    const children = permissions.filter(x => x.parent === id);

    result.push({
      "model": parent ? label : label.toUpperCase(),
      "key": id,
      "roles": roles,
      "children": children.map(y => {
        const { id, label, roles } = y;

        const children = permissions.filter(x => x.parent === id);

        if (children.length === 0) {
          return {
            "key": id,
            "model": label,
            "roles": roles,
          };
        }

        return {
          "key": id,
          "model": label,
          "roles": roles,
          "children": children.map(y => {
            const { id, label, roles } = y;

            return {
              "key": id,
              "model": label,
              "roles": roles,
            };
          }),
        };

      }),
    });
  });
  return result;
}

// UTILS
function mapPermissions(permissions = []) {

  let result;

  // 1- SORT BY `ORDER`
  const sortedList = permissions.sort((a, b) => a.order - b.order);

  // 2- FIND THE `ROOT` S
  const roots = sortedList.filter((x)=> x.parent === 0);

  // 3- FIND THE CHILDREN HIERARCHY BY EACH `ROOT`
  result =  getTree(roots, permissions);

  // return fakePermissionUI;
  return result;
}
