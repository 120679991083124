import { all, takeEvery, put, call } from 'redux-saga/effects';
import { createServiceREST, ManageError } from 'dcom-app/dist/services';
import { actions } from './controller';

function* crudToApi({ payload }) {
  const servicesApi = createServiceREST('document');

  const { data, actionName, callBack } = payload;
  const id = data ? data.id : 0;
  let result = null;
  let total = null;

  try {
    switch (actionName) {
      case 'CREATE':
        result = yield call(servicesApi.create, data);
        break;
      case 'READ':
        result = yield call(servicesApi.list, data);
        break;
      case 'READ_AND_COUNT':
        [total, result] = yield all([call(servicesApi.count, data), call(servicesApi.list, data)]);
        break;
      case 'UPDATE':
        delete data.id;
        result = yield call(servicesApi.update, id, data);
        break;
      case 'DELETE':
        result = yield call(servicesApi.delete, id);
        break;

      default:
        return false;
    }

    // errors
    if (result.isAxiosError) return ManageError(null, result);

    if (actionName === 'READ_AND_COUNT' && total.isAxiosError) return ManageError(null, total);

    // success
    if (actionName === 'READ') {
      yield put(actions.onLoadSuccess(result));
    }
    if (actionName === 'READ_AND_COUNT') {
      yield put(actions.onLoadAndCountSuccess(result, total.count));
    }
    if (callBack) {
      callBack();
    }
  } catch (error) {
    ManageError(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CRUD_API, crudToApi)]);
}
