import service from 'dcom-app/dist/services';

const servicesApi = {
  list: () =>
    service()
      .get('/company')
      .then(response =>  response.data)
      .catch(error => error),
  create: payload =>
    service()
      .post('/company', payload)
      .then(response => response.data)
      .catch(error => error),
  update: (id, payload) =>
    service()
      .patch(`/company/${id}`, payload)
      .then(response => response.data)
      .catch(error => error),
  delete: id =>
    service()
      .delete(`/company/${id}`)
      .then(response => response.data)
      .catch(error => error)
};

export default servicesApi;
