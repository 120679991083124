"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _clone = _interopRequireDefault(require("clone"));

var _themedefault = _interopRequireDefault(require("./themedefault"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var theme3 = (0, _clone["default"])(_themedefault["default"]);
theme3.palette.primary = ['#01a5ca', '#0db3d9', '#39b54a'];
theme3.palette.primary[10] = '#003768';
theme3.palette.color = ['#cccccc', '#c01b89', '#ce1d93', '#2576a2', '#2980b0', '#f8941e', '#f89b2e', '#8510dd', '#9129df', '#56b1a1', '#59c2b0', '#d6e287', '#e3ef92', '#a50900', '#a81c14'];
theme3.palette.fontColor = ['#5b5b5b', '#FFFFFF', '#39b54a'];
theme3.palette.bgColor = ['#FFFFFF', '#f4f4f4', '#39b54a', '#96d29e'];
theme3.palette.borderColor = ['#e5e5e5']; // Transitions
// const transitions = {
//   cubic: time => {
//     return `all ${time} cubic-bezier(0.25, 0.46, 0.45, 0.94)`;
//   },
//   cubic2: time => {
//     return `all ${time} cubic-bezier(.215,.61,.355,1)`;
//   },
//   cubic3: time => {
//     return `all ${time} cubic-bezier(.86,0,.07,1)`;
//   },
//   ease: time => {
//     return `all ${time} ease-in-out`;
//   }
// };

var _default = theme3;
exports["default"] = _default;