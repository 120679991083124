import service from "dcom-app/dist/services";

const servicesApi = {
    list: () =>
      service()
          .get('/user')
          .then(response => response.data)
          .catch(error => error),
    search: (data) =>
        service()
            .post('/search', data)
            .then(response => response.data)
            .catch(error => error)
};

export default servicesApi;
