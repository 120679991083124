// process.env.REACT_APP_ENV;
const ENV = 'production';
// const ENV = window.location.host === 'budget-tool-admin.debt.com' ? 'production' : 'development';
// 'localhost:3000'
// 'budget-tool-admin.debt.com'
console.log('stated like:', ENV);

const BASE_URL =
  ENV === 'production' ? 'https://mobile-backend-api.debt.com' : 'https://dev-mobile-backend-api.debt.com';

const BASE_URL_DROPS_API = '';
// (ENV === 'production') ? 'https://drops-api.debt.com' : 'https://dev-drops-api.debt.com';

export { BASE_URL, BASE_URL_DROPS_API, ENV };
