import asyncComponent from 'dcom-app/dist/helpers/AsyncFunc';

const modulesRoutes = [
  {
    path: '',
    component: asyncComponent(() => import('./containers/dashboard')),
    roles: [1, 2]
  },
  {
    path: 'company',
    component: asyncComponent(() => import('./containers/company')),
    roles: [1]
  },
  {
    path: 'company-info',
    component: asyncComponent(() => import('./containers/company-info')),
    roles: [1]
  },
  {
    path: 'counselor',
    component: asyncComponent(() => import('./containers/counselor')),
    roles: [1]
  },
  {
    path: 'lead',
    component: asyncComponent(() => import('./containers/lead')),
    roles: [1]
  },
  {
    path: 'users-grid',
    component: asyncComponent(() => import('./containers/users-grid')),
    roles: [1]
  },
  {
    path: 'user-details/:id',
    component: asyncComponent(() => import('./containers/user-details')),
    roles: [1]
  },
  {
    path: 'app-attributes',
    component: asyncComponent(() => import('./containers/app-attributes')),
    roles: [1]
  },
  {
    path: 'attribute-group',
    component: asyncComponent(() => import('./containers/config-attributes')),
    roles: [1]
  },
  {
    path: 'recommendation-spending',
    component: asyncComponent(() => import('./containers/config-spending')),
    roles: [1]
  },
  {
    path: 'documents',
    component: asyncComponent(() => import('./containers/documents')),
    roles: [1]
  },
  {
    path: 'messages',
    component: asyncComponent(() => import('./containers/messages')),
    roles: [1]
  },
  {
    path: 'categories',
    component: asyncComponent(() => import('./containers/category')),
    roles: [1]
  },
  {
    path: 'user',
    component: asyncComponent(() => import('./containers/user')),
    roles: [1],
  },
  {
    path: 'role',
    component: asyncComponent(() => import('./containers/role')),
    roles: [1],
  },
  {
    path: 'permission',
    component: asyncComponent(() => import('./containers/permission')),
    roles: [1],
  },
  {
    path: 'permission-ui',
    component: asyncComponent(() => import('./containers/permissionUI')),
    roles: [1],
  },
];

export const getPublicRouters = roles => {
  return modulesRoutes.filter(v => v.roles.length === 0);
};

export const getPrivateRouters = roles => {
  return modulesRoutes.filter(r => r.roles.length > 0 && r.roles.some(value => roles.includes(value)));
};

// export const getAppRouters = (roles, isLoggedIn) => {
//   const publicRouters = getPublicRouters(roles);
//   const privateRouters = getPrivateRouters(roles);
//
//   if (!isLoggedIn) return [...publicRouters];
//
//   return [...publicRouters, ...privateRouters];
// };


export const getAppRouters = (roles, isLoggedIn, permissions= []) => {

  if (!permissions || permissions.length === 0) return [];

  const result = [];

  permissions.forEach((x) => {

    const p = x.route || '';

    if (!result.find((x)=>x.path === p)){
      const m = modulesRoutes.find((x)=>x.path === p);
      if (m){
        result.push(m)
      }
    }

  })

  // const publicRouters = getPublicRouters(roles);
  // const privateRouters = getPrivateRouters(roles);
  //
  // if (!isLoggedIn) return [...publicRouters];
  //
  // return [...publicRouters, ...privateRouters];

  return result;
};
