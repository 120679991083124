"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.ButtonGroup = void 0;

var _antd = require("antd");

var _button = require("./button.style");

var _withDirection = _interopRequireDefault(require("../../settings/withDirection"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var AntButton = (0, _button.Buttons)(_antd.Button);
var isoButton = (0, _withDirection["default"])(AntButton);
var AntButtonGroup = (0, _button.ButtonsGroup)(_antd.Button.Group);
var ButtonGroup = (0, _withDirection["default"])(AntButtonGroup); // export default isoButton;

exports.ButtonGroup = ButtonGroup;
var _default = AntButton;
exports["default"] = _default;