import service from "dcom-app/dist/services";

const servicesApi = {
    list: () =>
        service()
            .get('/configurationgroup')
            .then(response => response.data)
            .catch(error => error),
    create: payload =>
        service()
            .post('/configurationgroup', payload)
            .then(response => response.data)
            .catch(error => error),
    delete: id =>
        service()
            .delete(`/configurationgroup/${id}`)
            .then(response => response.data)
            .catch(error => error),
    loadDetails: (id) =>
        service()
            .get(`/configuration/${id}`)
            .then(response => response.data)
            .catch(error => error),
    createBulk: payload =>
      service()
        .post('/configuration', payload)
        .then(response => response.data)
        .catch(error => error),
    updateBulk: (id, payload) =>
        service()
            .patch(`/configuration/${id}`, payload)
            .then(response => response.data)
            .catch(error => error),
    companiesAttached: (id) =>
        service()
            .get(`/company/${id}`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
