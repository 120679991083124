import service from 'dcom-app/dist/services';

const servicesApi = {
  list: payload =>
    service()
      .get('/user', { params: payload })
      .then(response => response.data)
      .catch(error => error),
  count: () =>
    service()
      .get('/count/user')
      .then(response => response.data)
      .catch(error => error),
  create: payload =>
    service()
      .post('/user', payload)
      .then(response => response.data)
      .catch(error => error),
  update: (id, payload) =>
    service()
      .patch(`/user/${id}`, payload)
      .then(response => response.data)
      .catch(error => error),
  delete: id =>
    service()
      .delete(`/user/${id}`)
      .then(response => response.data)
      .catch(error => error),
  // filter: payload =>
  //   service()
  //     .get(`/user`, { params: payload })
  //     .then(response => response.data)
  //     .catch(error => error),
  // filterOr: (firstName, email) =>
  //   service()
  //     .get(
  //       `/role/3/users?where={"or":[{"firstName":{"contains":"${firstName}"},"email":{"contains":"${email}"}}]}`,
  //     )
  //     .then(response => response.data)
  //     .catch(error => error),
};

export default servicesApi;
