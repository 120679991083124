const MODULE = 'DASHBOARD';

const actions = {
    LOAD:`LOAD_${MODULE}`,
    LOAD_SUCCESS: `LOAD_SUCCESS_${MODULE}`,
    LOAD_ERROR: `LOAD_ERROR_${MODULE}`,

    // load
    load: (startDate, endDate, affiliateId) => {
        return {
            type: actions.LOAD,
            payload: {startDate, endDate, affiliateId}
        };
    },
    loadSuccess: data => ({
        type: actions.LOAD_SUCCESS,
        payload: { data },
    }),
    loadError: error => ({
        type: actions.LOAD_ERROR,
        payload: { error },
    }),
};
export default actions;
