"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ManageError = void 0;
exports.createServiceREST = createServiceREST;
exports["default"] = void 0;
exports.getUrlBase = getUrlBase;

var _axios = _interopRequireDefault(require("axios"));

var _utility = require("../helpers/utility");

var _MainApp = require("../containers/main/MainApp");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function getUrlBase() {
  return _MainApp.services.apiUrl;
}
/**
 * Create a Global instance for manage all services.
 * @return {AxiosInstance}
 */


function service() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

  var s = _axios["default"].create({
    baseURL: url === null ? _MainApp.services.apiUrl : url,
    timeout: 10000,
    headers: {
      Authorization: "JWT ".concat((0, _utility.getToken)())
    }
  });

  return s;
}

var ManageError = function ManageError(error, response) {
  if (error) {
    throw error;
  }

  var message = response.message;

  if (response && response.status === 401) {
    throw new Error('Unauthorised');
  }

  throw new Error(message);
};

exports.ManageError = ManageError;

function createServiceREST(endpoint) {
  function customService() {
    var s = _axios["default"].create({
      baseURL: _MainApp.services.apiUrl,
      timeout: 10000,
      headers: {
        Authorization: "JWT ".concat((0, _utility.getToken)())
      }
    });

    return s;
  }

  var service = customService();
  return {
    list: function list(payload) {
      return service.get("/".concat(endpoint), {
        params: payload
      }).then(function (response) {
        return response.data;
      })["catch"](function (error) {
        return error;
      });
    },
    count: function count(payload) {
      return service.get("/count/".concat(endpoint), {
        params: payload
      }).then(function (response) {
        return response.data;
      })["catch"](function (error) {
        return error;
      });
    },
    create: function create(payload) {
      return service.post("/".concat(endpoint), payload).then(function (response) {
        return response.data;
      })["catch"](function (error) {
        return error;
      });
    },
    update: function update(id, payload) {
      return service.patch("/".concat(endpoint, "/").concat(id), payload).then(function (response) {
        return response.data;
      })["catch"](function (error) {
        return error;
      });
    },
    "delete": function _delete(id) {
      return service["delete"]("/".concat(endpoint, "/").concat(id)).then(function (response) {
        return response.data;
      })["catch"](function (error) {
        return error;
      });
    }
  };
}

var _default = service;
exports["default"] = _default;