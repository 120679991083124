"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _config = require("../../containers/ThemeSwitcher/config");

var actions = {
  CHANGE_THEME: "CHANGE_THEME",
  SWITCH_ACTIVATION: "SWITCH_ACTIVATION",
  switchActivation: function switchActivation() {
    return {
      type: actions.SWITCH_ACTIVATION
    };
  },
  changeTheme: function changeTheme(attribute, themeName) {
    var theme = (0, _config.getCurrentTheme)(attribute, themeName);

    if (attribute === "layoutTheme") {
      document.getElementsByClassName("isomorphicContent")[0].style.backgroundColor = theme.backgroundColor;
    }

    return {
      type: actions.CHANGE_THEME,
      attribute: attribute,
      theme: theme
    };
  }
};
var _default = actions;
exports["default"] = _default;