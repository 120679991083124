import { all, takeEvery, call, put } from 'redux-saga/effects';
import { message as MessageManager } from "antd";
import servicesApi from "./services";
import actions from './actions';

function* list(action) {
    try {

        const {startDate, endDate, affiliateId} = action.payload;
        const [
            summary,
            countCompany,
            countAffiliates,
            countCreative,
            countUser,
            countWidget
        ] = yield all([
            call(servicesApi.dailySummary, startDate, endDate, affiliateId),
            call(servicesApi.countCompany),
            call(servicesApi.countAffiliate),
            call(servicesApi.countCreative),
            call(servicesApi.countUser),
            call(servicesApi.countWidget),
        ]);

        // success
        if (!summary.isAxiosError
            && !countCompany.isAxiosError
            && !countAffiliates.isAxiosError
            && !countCreative.isAxiosError
            && !countUser.isAxiosError
            && !countWidget.isAxiosError
        ) {
            const dashboard = {
                summary: summary.response.ArrayOfDailySummary.DailySummary, //response.ArrayOfDailySummary.DailySummary;
                countCompany,
                countAffiliates,
                countCreative,
                countUser,
                countWidget
            };
            yield put(actions.loadSuccess(dashboard));
            return;
        }

        // error
        const summaryMessage = summary.message;
        const countCompanyMessage = countCompany.message;
        const countAffiliatesMessage = countAffiliates.message;
        const countCreativeMessage = countCreative.message;
        const countUserMessage = countUser.message;
        const countWidgetMessage = countWidget.message;

        if(summaryMessage){
            console.log("Error: list dashboard:-->", summaryMessage);
            yield put(actions.loadError({error: summaryMessage}));
        }

        if(countCompanyMessage){
            console.log("Error: list dashboard:-->", countCompanyMessage);
            yield put(actions.loadError({error: countCompanyMessage}));
        }

        if(countAffiliatesMessage){
            console.log("Error: list dashboard:-->", countAffiliatesMessage);
            yield put(actions.loadError({error: countAffiliatesMessage}));
        }

        if(countCreativeMessage){
            console.log("Error: list dashboard:-->", countCreativeMessage);
            yield put(actions.loadError({error: countCreativeMessage}));
        }

        if(countUserMessage){
            console.log("Error: list dashboard:-->", countUserMessage);
            yield put(actions.loadError({error: countUserMessage}));
        }

        if(countWidgetMessage){
            console.log("Error: list dashboard:-->", countWidgetMessage);
            yield put(actions.loadError({error: countWidgetMessage}));
        }

        if (summary.response && summary.response.status === 401) {
            MessageManager.error('Session expired please login again');
        }

    } catch (error) {
        console.log(error);
        yield put(actions.loadError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD, list),
    ]);
}
