/* eslint-disable no-unused-vars */
/* eslint-disable camelcase,no-restricted-syntax */
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { createServiceREST, ManageError } from 'dcom-app/dist/services';
import { actions } from './controller';

function* listByCreator({ payload }) {
  try {
    const { callBack, id } = payload;

    const servicesApi = yield createServiceREST(`message/by_creator/${id}`);

    let result = yield call(servicesApi.list, {});

    // error
    if (result.isAxiosError) {
      // error
      return ManageError(null, result);
    }

    // success
    yield put(actions.onLoadSuccess(result.data));

    if (callBack) {
      callBack();
    }
  } catch (error) {
    ManageError(error);
  }
}

function* listByRecipient({ payload }) {
  try {
    const { callBack, id } = payload;
    const servicesApi = yield createServiceREST(`message/by_recipient/${id}`);

    let result = yield call(servicesApi.list, {});

    // error
    if (result.isAxiosError) {
      // error
      return ManageError(null, result);
    }

    // success
    yield put(actions.onLoadSuccess(result.data));

    if (callBack) {
      callBack();
    }
  } catch (error) {
    ManageError(error);
  }
}

function* updateMessage({ payload }) {
  try {
    const { data, callBack } = payload;
    const { id, ...rest } = data;

    const servicesApi = yield createServiceREST(`messagerecipient`);

    let result = yield call(servicesApi.update, id, rest);

    // error
    if (result.isAxiosError) {
      // error
      return ManageError(null, result);
    }

    // success
    // yield put(actions.onLoadSuccess(result.data));

    if (callBack) {
      callBack();
    }
  } catch (error) {
    ManageError(error);
  }
}

function* createMessage({ payload }) {
  try {
    const { callBack, data } = payload;

    const { subject, body, bucket, creator_id, parent_id, thread_id, recipient_id } = data;

    const servicesApi = yield createServiceREST(`message`);

    const result = yield call(servicesApi.create, { subject, body, bucket, creator_id });

    // error
    if (result.isAxiosError) {
      // error
      return ManageError(null, result);
    }

    const { id } = result;

    const servicesApi2 = yield createServiceREST(`messagerecipient`);

    for (const leadId of recipient_id) {
      const data2 = {
        message_id: id,
        is_read: false,
        is_archived: false,
        recipient_id: leadId,
        parent_id: parent_id || 0,
        thread_id: thread_id || 0,
      };

      yield call(servicesApi2.create, data2);
    }

    // success
    yield put(actions.onLoadError([]));

    if (callBack) {
      callBack();
    }
  } catch (error) {
    ManageError(error);
  }
}

function* crudToApi({ payload }) {
  const servicesApi = createServiceREST('message');

  const { data, actionName, callBack } = payload;
  const id = data ? data.id : 0;
  let result = null;

  try {
    switch (actionName) {
      case 'CREATE':
        result = yield call(servicesApi.create, data);
        break;

      case 'READ':
        result = yield call(servicesApi.list, data);
        break;

      case 'UPDATE':
        delete data.id;
        result = yield call(servicesApi.update, id, data);
        break;

      case 'DELETE':
        result = yield call(servicesApi.delete, id);
        break;

      default:
        break;
    }

    // success
    if (result.isAxiosError) {
      // error
      return ManageError(null, result);
    }

    // success
    if (actionName === 'READ') {
      yield put(actions.onLoadSuccess(result));
    } else {
      // yield put({ type: actions.CRUD_API, payload: { actionName: 'READ' } });
    }

    if (callBack) {
      callBack();
    }
  } catch (error) {
    ManageError(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CRUD_API, crudToApi), takeEvery(actions.CREATE_MESSAGE, createMessage)]);
}
