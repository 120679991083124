/* eslint-disable camelcase,no-nested-ternary */
const mailActions = {
  FILTER_ATTRIBUTE: 'FILTER_ATTRIBUTE',
  FILTER_ATTRIBUTE_SUCCESS: 'FILTER_ATTRIBUTE_SUCCESS',
  SELECTED_MAIL: 'SELECTED_MAIL',
  COMPOSE_MAIL: 'COMPOSE_MAIL',
  REPLY_MAIL: 'REPLY_MAIL',
  SEARCH_STRING: 'SEARCH_STRING',

  filterAction: (newFilterAttr) => {
    return (dispatch, getState) => {
      const { filterAttr } = getState().Mails;
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          filterAttr.bucket = newFilterAttr.bucket;
          filterAttr.tag = newFilterAttr.tag;
        } else if (newFilterAttr.tag) {
          filterAttr.tag = newFilterAttr.tag;
        }
      }
      dispatch({
        type: mailActions.FILTER_ATTRIBUTE,
        filterAttr,
      });
    };
  },
  selectMail: (selectedMail) => {
    return (dispatch, getState) => {
      const { allMails } = getState().Mails;
      allMails[allMails.findIndex((mail) => mail.id === selectedMail)].read = true;
      dispatch({
        type: mailActions.SELECTED_MAIL,
        selectedMail,
        allMails,
      });
    };
  },
  changeComposeMail: (composeMail) => ({
    type: mailActions.COMPOSE_MAIL,
    composeMail,
  }),
  changeReplyMail: (replyMail) => ({
    type: mailActions.REPLY_MAIL,
    replyMail,
  }),
  changeSearchString: (searchString) => ({
    type: mailActions.SEARCH_STRING,
    searchString,
  }),
  onFilterSuccess: (data) => {
    const prepared = data.map((x) => {
      const { creator_id } = x;

      return {
        ...x,
        date: x.createdAt, // '2017-05-18T04:39:53.974Z',
        email: x.from,
        name: `${creator_id?.firstName} ${creator_id?.lastName}`,
        bucket: creator_id?.bucket === 'offer' ? 'Offer' : creator_id?.id === 1 ? 'Sent' : 'Inbox',
        // cc: 'cc coming',
      };
    });

    return {
      type: mailActions.FILTER_ATTRIBUTE_SUCCESS,
      payload: prepared,
    };
  },
};
export default mailActions;
