import service from "dcom-app/dist/services";

export const servicesConfigurationSpending = {
    list: () =>
        service()
            .get('/configurationSpending')
            .then(response => response.data)
            .catch(error => error),
    create: payload =>
        service()
            .post('/configurationSpending', payload)
            .then(response => response.data)
            .catch(error => error),
    update: (id, payload) =>
        service()
            .patch(`/configurationSpending/${id}`, payload)
            .then(response => response.data)
            .catch(error => error),
    delete: id =>
        service()
            .delete(`/configurationSpending/${id}`)
            .then(response => response.data)
            .catch(error => error),
};

export const servicesConfigurationSpendingCategory = {
    list: (params = {}) =>
      service()
        .get('/configurationSpendingCategory', {params})
        .then(response => response.data)
        .catch(error => error),
    create: payload =>
      service()
        .post('/configurationSpendingCategory', payload)
        .then(response => response.data)
        .catch(error => error),
    update: (id, payload) =>
      service()
        .patch(`/configurationSpendingCategory/${id}`, payload)
        .then(response => response.data)
        .catch(error => error),
    delete: id =>
      service()
        .delete(`/configurationSpendingCategory/${id}`)
        .then(response => response.data)
        .catch(error => error),
};


