import service from "dcom-app/dist/services";

const servicesApi = {
    list: () =>
        service()
            .get('/appattributes?limit=1000')
            .then(response => response.data)
            .catch(error => error),
    create: payload =>
        service()
            .post('/appattributes', payload)
            .then(response => response.data)
            .catch(error => error),
    update: (id, payload) =>
        service()
            .patch(`/appattributes/${id}`, payload)
            .then(response => response.data)
            .catch(error => error),
    delete: id =>
        service()
            .delete(`/appattributes/${id}`)
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
