import service from "dcom-app/dist/services";

const servicesApi = {

    dailySummary: (startDate, endDate, affiliateId) =>
        service()
            .get(`/report/daily_summary?startDate=${startDate}&endDate=${endDate}&affiliateId=${affiliateId}`)
            .then(response => response.data)
            .catch(error => error),

    countCompany: () =>
        service()
            .get('/count/company')
            .then(response => response.data)
            .catch(error => error),

    countUser: () =>
        service()
            .get('/count/user')
            .then(response => response.data)
            .catch(error => error),

    countAffiliate: () =>
        service()
            .get('/count/affiliate')
            .then(response => response.data)
            .catch(error => error),

    countCreative: () =>
        service()
            .get('/count/creative')
            .then(response => response.data)
            .catch(error => error),

    countWidget: () =>
        service()
            .get('/count/widget')
            .then(response => response.data)
            .catch(error => error),
};

export default servicesApi;
