import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  models: [],
  selected: null,
  modelDetails: null,
  companiesAttached: []
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.LOAD:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false
      };

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        errorMessage: false
      };

    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem'
      };

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        selected: payload.data || null
      };

    case actions.UPDATE_SELECTED:
      return {
        ...state,
        selected: payload.data
      };

    case actions.LOAD_DETAILS:
      return {
        ...state,
        modelDetails: null
      };

    case actions.LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        modelDetails: payload.data
      };

    case actions.LOAD_COMPANIES_ATTACHED_SUCCESS:
      return {
        ...state,
        companiesAttached: payload.data
      };

    default:
      return state;
  }
}
