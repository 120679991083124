"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CREATE: 'CREATE_USER',
  LOAD_PERMISSIONS: 'LOAD_PERMISSIONS_APP',
  LOAD_PERMISSIONS_SUCCESS: 'LOAD_PERMISSIONS_SUCCESS_APP',
  TWO_FA: 'TWO_FA',
  checkAuthorization: function checkAuthorization() {
    var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return {
      type: actions.CHECK_AUTHORIZATION,
      payload: payload
    };
  },
  login: function login() {
    var token = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return {
      type: actions.LOGIN_REQUEST,
      payload: {
        token: token
      }
    };
  },
  twoFA: function twoFA() {
    var _twoFA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    return {
      type: actions.TWO_FA,
      payload: {
        twoFA: _twoFA
      }
    };
  },
  logout: function logout() {
    return {
      type: actions.LOGOUT
    };
  },
  create: function create(userData, redirect) {
    return {
      type: actions.CREATE,
      payload: {
        userData: userData,
        redirect: redirect
      }
    };
  },
  loadPermissions: function loadPermissions(roles) {
    return {
      type: actions.LOAD_PERMISSIONS,
      payload: {
        roles: roles
      }
    };
  },
  onLoadPermissionsSuccess: function onLoadPermissionsSuccess(data) {
    return {
      type: actions.LOAD_PERMISSIONS_SUCCESS,
      payload: {
        data: data
      }
    };
  }
};
var _default = actions;
exports["default"] = _default;